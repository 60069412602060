import React from 'react';
import get from 'lodash/get';
import {
  FieldErrors,
  UseFormRegister,
  RegisterOptions,
  FieldValues,
  Path,
} from 'react-hook-form';
import { BaseFormInput, BaseFormInputProps } from './BaseInput';

export interface FormInputProps<D extends FieldValues = any>
  extends Omit<BaseFormInputProps, 'error' | 'ref' | 'name'> {
  options?: RegisterOptions<D>;
  register: UseFormRegister<D>;
  name: Path<D>;
  errors: FieldErrors;
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  register,
  errors,
  options,
  isRequired,
  ...rest
}: FormInputProps) => {
  const error = get(errors, name, undefined);
  const formValues = register(name as string, options);
  const required = formValues.required || isRequired;

  return (
    <BaseFormInput
      error={error}
      isRequired={required}
      {...rest}
      {...formValues}
    />
  );
};
