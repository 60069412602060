import { GetServerSidePropsContext } from 'next';
import router from 'next/router';
import { config, UserDetailsFragment, REDIRECT_KEYS } from '@pepper/client';
import { GetServerSidePropsResult } from '@pepper/client/ssr';
import { assertUnreachable } from '@pepper/types';

export const getRedirectUrl = (redirect?: string, path?: string): string => {
  const redirectKey: typeof REDIRECT_KEYS[number] | undefined =
    redirect && REDIRECT_KEYS.includes(redirect.toLowerCase() as any)
      ? (redirect.toLowerCase() as typeof REDIRECT_KEYS[number])
      : undefined;

  switch (redirectKey) {
    case 'admin':
      return `${config.ADMIN_CLIENT_URL}${path ?? ''}`;
    case 'cook':
      return `${config.COOK_CLIENT_URL}${path ?? ''}`;
    case 'orders':
      return `${config.OMS_CLIENT_URL}${path ?? ''}`;
    case 'sales':
      return `${config.SALES_CLIENT_URL}${path ?? ''}`;
    case undefined:
      return '/';
    default:
      assertUnreachable(redirectKey);
  }
};

export const redirectTo = async (
  redirect?: string,
  path?: string,
): Promise<void> => {
  const url = getRedirectUrl(redirect, path);
  if (url.startsWith('/')) {
    await router.push(url);
  } else {
    window.location.assign(url);
  }
};

export const serverSideRedirect = async (
  ctx: GetServerSidePropsContext,
  { user }: { user?: UserDetailsFragment | null } = {},
): Promise<GetServerSidePropsResult> => {
  if (!user) {
    return {
      props: {},
    };
  }

  const url = getRedirectUrl(
    ctx.query?.redirect as string,
    ctx.query?.path as string,
  );

  return {
    props: {},
    redirect: {
      permanent: false,
      destination: url,
    },
  };
};
